import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { UserRoleResponse, UserRoleResponseItem } from '@/models/users';

@Module({
    namespaced: true,
    dynamic: true,
    name: `roles.list`,
    store,
})
class RoleListStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: UserRoleResponseItem[] = []; // TODO: Replace this with a better interface when we have an API

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: UserRoleResponseItem[]) {
        this.response = payload;
    }

    @Action
    retrieveRoles() {
        // Retrieve Teacher Roles
        axios.get<UserRoleResponse>(`masterdata/roles`)
            .then((response) => {
                if (response.data && response.data.code === 200) {
                    this.context.commit(`setResponse`, response.data.data.objects);
                }

                if (response.data.code >= 400) {
                    Toast.open({
                        type: `is-danger`,
                        duration: 3000, // 3 Seconds
                        message: `There is an issue with fetching roles. Please try again`,
                    });
                }
            }).catch((error) => {
                this.context.commit(`setError`, error);
            });
            this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(RoleListStore);