

























import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import CourseFormTemplate from '@/components/courses/CourseFormTemplate.vue';
import { CourseStores } from '@/store/course/index';
import { DropdownItem } from '@/models/dropdown';
import { CourseItemUpdateRequestForAxios, CourseItemUpdateRequest } from '@/models/courses';
import { SubjectStores } from '@/store/subjects';
import { FormStores } from '@/store/forms';
import { TutorStores } from '@/store/tutors';
import { LanguageStores } from '@/store/languages';
import { monthDurationDropdown, VideoFileSizeLimit, weekDaysDropdown, weekDurationDropdown, repeatTypesDropdown } from '@/utils/constants';
import moment from 'moment';
import { uploadAttachment } from '@/utils/attachments';
import { RoleStores } from '@/store/roles';
import { addMinutesToCurrentDate, getDifferenceInMinutes } from '@/utils/moment';
@Component({
    components: { Page, BackButtonLayout, CourseFormTemplate }
})
export default class EditCourses extends Vue {
    courseEditStore = CourseStores.details;
    courseStateStore = CourseStores.state;
    subjectListStore = SubjectStores.list;
    levelListStore = FormStores.list;
    tutorListStore = TutorStores.list;
    languageListStore = LanguageStores.list;
    roleListStore = RoleStores.list;
    courseLanguageData: DropdownItem[] = [];
    courseSubjectData: DropdownItem[] = [];
    courseLevelData: DropdownItem[] = [];
    courseTutorData: DropdownItem[] = [];
    courseTimetableDays: DropdownItem[] = [];
    courseMonthsDuration: DropdownItem[] = [];
    courseWeekDuration: DropdownItem[] = [];
    courseRepeatTypes: DropdownItem[] = [];
    formRequestData: CourseItemUpdateRequest = {
        id: parseInt(this.$route.params.id),
        formId: 4,
        subjectId: 1,
        languageId: 1,
        isFeatured: false,
        tuitionId: 1,
        teacherId: 3,
        courseName: 'Edit Course',
        price: '123.00',
        discountPrice: '',
        description: 'This is a sample for Edit Courses',
        startDate: new Date(),
        endDate: new Date(),
        repeatType: 'Never',
        repeatOnDay: 'number',
        repeatNumber: 1,
        weekDays: ['Wednesday', 'Thursday'],
        courseStartTime: new Date(),
        courseEndTime: new Date(),
        videoUrl: "",
        backgroundImageUrl: "",
        courseImageUrl: "",
        videoThumbnailUrl: "",
        studyTrack: {},
    };
    
    get layoutTitle() {
        return `Edit Current Course`;
    }

    get backRoute() {
        return `/courses`;
    }

    get languages() {
        return this.courseLanguageData;
    }

    get subjects() {
        return this.courseSubjectData;
    }

    get levels() {
        return this.courseLevelData;
    }

    get tutors() {
        return this.courseTutorData;
    }

    get timetableDays() {
        return this.courseTimetableDays;
    }

    get months() {
        return this.courseMonthsDuration;
    }

    get weeks() {
        return this.courseWeekDuration;
    }

    get repeatTypes() {
        return this.courseRepeatTypes;
    }

    get showTemplate() {
        return this.languages.length > 0 && this.subjects.length > 0 && this.levels.length > 0 && this.tutors.length > 0;
    }

    get isCourseNameDuplicated() {
        return this.courseEditStore.isCourseNameDuplicated;
    }

    // TODO: Replace this with the API calls
    mounted() {
        this.courseEditStore.setTimeOut(null);
        this.courseEditStore.fetchCourseById(parseInt(this.$route.params.id));
        this.roleListStore.retrieveRoles();
    }

    getFormDropdown(subjectId: number) {
        this.levelListStore.retrieveForms({ subjectid: subjectId });
    }

    getTutorDropdown() {
        const teacherIndex = this.roleListStore.response.findIndex((role) => {
            return role.shortname === 'teacher';
        });
        if (teacherIndex > -1) {
            this.tutorListStore.retrieveTutors(this.roleListStore.response[teacherIndex].shortname);
        }
    }

    resetNameError(payload: boolean) {
        this.courseEditStore.updateIsCourseNameDuplicated(payload);
    }

    @Watch(`roleListStore.response`)
    fetchTeachers() {
        if (this.roleListStore.response.length > 0) {
            this.getTutorDropdown();
        }
    }

    @Watch(`courseEditStore.fetchedCurrentCourse`)
    updateCurrentCourse() {
        if (this.courseEditStore.fetchedCurrentCourse && this.roleListStore.response) {
            // Split start time and start date from start date;
            const currentStartDate = moment(this.courseEditStore.fetchedCurrentCourse.startDate.split('T')[0]).toDate();
            const currentStartTime = moment(this.courseEditStore.fetchedCurrentCourse.startDate).toDate();
            const currentEndDate = moment(this.courseEditStore.fetchedCurrentCourse.endDate.split('T')[0]).toDate();
            const currentEndTime = moment(this.courseEditStore.fetchedCurrentCourse.endDate).toDate();
            this.formRequestData = {
                id: this.courseEditStore.fetchedCurrentCourse.id,
                formId: this.courseEditStore.fetchedCurrentCourse.form.id,
                subjectId: this.courseEditStore.fetchedCurrentCourse.subject.id,
                tuitionId: this.courseEditStore.fetchedCurrentCourse.tuition.id,
                teacherId: this.courseEditStore.fetchedCurrentCourse.teacher.id,
                isFeatured: this.courseEditStore.fetchedCurrentCourse.isFeatured,
                languageId: this.courseEditStore.fetchedCurrentCourse.language.id,
                courseName: this.courseEditStore.fetchedCurrentCourse.courseName,
                price: this.courseEditStore.fetchedCurrentCourse.price.toString(),
                discountPrice: this.courseEditStore.fetchedCurrentCourse.discountPrice === 0 ? '' : this.courseEditStore.fetchedCurrentCourse.discountPrice.toString(),
                description: this.courseEditStore.fetchedCurrentCourse.description,
                startDate: currentStartDate,
                endDate: currentEndDate,
                repeatType: this.courseEditStore.fetchedCurrentCourse.repeatType,
                repeatOnDay: this.courseEditStore.fetchedCurrentCourse.repeatOnDay,
                repeatNumber: this.courseEditStore.fetchedCurrentCourse.repeatNumber,
                weekDays: this.courseEditStore.fetchedCurrentCourse.weekDays,
                courseStartTime: currentStartTime,
                courseEndTime: currentEndTime,
                videoUrl: this.courseEditStore.fetchedCurrentCourse.videoUrl,
                backgroundImageUrl: this.courseEditStore.fetchedCurrentCourse.backgroundImageUrl,
                videoThumbnailUrl: this.courseEditStore.fetchedCurrentCourse.videoThumbnailUrl,
                courseImageUrl: this.courseEditStore.fetchedCurrentCourse.courseImageUrl,
                studyTrack: this.courseEditStore.fetchedCurrentCourse.studyTrack,
            };
            this.courseTimetableDays = weekDaysDropdown;
            this.courseMonthsDuration = monthDurationDropdown;
            this.courseWeekDuration = weekDurationDropdown;
            this.courseRepeatTypes = repeatTypesDropdown;
            this.subjectListStore.retrieveSubjects({});
            this.courseLanguageData = [{
                text: `English`,
                value: `1`,
                uniqueKey: `English_`
            }];
            this.getFormDropdown(this.formRequestData.subjectId);
            this.getTutorDropdown();
            this.languageListStore.retrieveLanguages();
        }
    }

    @Watch(`subjectListStore.response`)
    updateSubjectDropdown() {
        if (this.subjectListStore.response) {
            this.courseSubjectData = [];
            this.subjectListStore.response.objects.forEach((item) => {
                this.courseSubjectData.push({
                    text: item.subjectName,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`levelListStore.response`)
    updateFormDropdown() {
        if (this.levelListStore.response) {
            this.courseLevelData = [];
            this.levelListStore.response.objects.forEach((item) => {
                this.courseLevelData.push({
                    text: item.formName,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`tutorListStore.response`)
    updateTutorDropdown() {
        if (this.tutorListStore.response) {
            this.courseTutorData = [];
            this.tutorListStore.response.objects.forEach((item) => {
                this.courseTutorData.push({
                    text: `${item.name} (${item.email})`,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`languageListStore.response`)
    updateLanguageDropdown() {
        if (this.languageListStore.response) {
            this.courseLanguageData = [];
            this.languageListStore.response.forEach((data) => {
                this.courseLanguageData.push({
                    text: data.name,
                    value: data.id.toString(),
                    uniqueKey: `${data.id}_`
                });
            });
        }
    }

    async editCourse(payload: CourseItemUpdateRequest) {
        // We will need to compare. If it is the same, then don't add it to the request
        //TODO: Update image and video attachments
        const onlyStartDate = moment(payload.startDate).format('YYYY-MM-DD');
        const onlyStartTime = moment(payload.courseStartTime).format(`HH:mm:ss`);
        const combinedStartDateTime = moment(onlyStartDate + 'T' + onlyStartTime).toDate();
        const formattedDate = moment(combinedStartDateTime).format(`YYYY-MM-DDTHH:mm`);

        const onlyEndDate = moment(payload.endDate).format('YYYY-MM-DD');
        const onlyEndTime = moment(payload.courseEndTime).format(`HH:mm:ss`);
        const combinedEndDateTime = moment(onlyEndDate + 'T' + onlyEndTime).toDate();

        let data: Partial<CourseItemUpdateRequestForAxios> = {
            id: this.$route.params.id
        };
        if (this.formRequestData.repeatType !== payload.repeatType) {
            data.repeatType = payload.repeatType;
            data.repeatNumber = payload.repeatNumber;
            data.repeatOnDay = payload.repeatOnDay;
            data.weekDays = payload.weekDays;
        }

        if (this.formRequestData.repeatOnDay !== payload.repeatOnDay) {
            data.repeatOnDay = payload.repeatOnDay;
        }

        if (this.formRequestData.repeatNumber !== payload.repeatNumber) {
            data.repeatNumber = payload.repeatNumber;
        }

        if (this.formRequestData.isFeatured !== payload.isFeatured) {
            data.isFeatured = payload.isFeatured;
        }

        if (this.formRequestData.formId !== payload.formId) {
            data.formId = payload.formId;
        }

        if (this.formRequestData.subjectId !== payload.subjectId) {
            data.subjectId = payload.subjectId;
        }

        if (this.formRequestData.languageId !== payload.languageId) {
            data.languageId = payload.languageId;
        }

        if (this.formRequestData.tuitionId !== payload.tuitionId) {
            data.tuitionId = payload.tuitionId;
        }

        if (this.formRequestData.teacherId !== payload.teacherId) {
            data.teacherId = payload.teacherId;
        }

        if (this.formRequestData.courseName.trim() !== payload.courseName.trim()) {
            data.courseName = payload.courseName;
        }

        if (this.formRequestData.price !== payload.price) {
            data.price = payload.price;
        }

        if (this.formRequestData.discountPrice !== payload.discountPrice) {
            data.discountPrice = payload.discountPrice;
        }

        if (this.formRequestData.description !== payload.description) {
            data.description = payload.description;
        }

        if (moment(this.courseEditStore.fetchedCurrentCourse!.startDate).diff(combinedStartDateTime) !== 0) {
            data.startDate = formattedDate;
            data.endDate = moment(combinedEndDateTime).format(`YYYY-MM-DDTHH:mm`);
        }

        if (moment(this.courseEditStore.fetchedCurrentCourse!.endDate).diff(combinedEndDateTime) !== 0) {
            data.startDate = formattedDate;
            data.endDate = moment(combinedEndDateTime).format(`YYYY-MM-DDTHH:mm`);
        }

        // const minuteDifference = getDifferenceInMinutes(payload.courseStartTime, payload.courseEndTime);

        // if ( minuteDifference > 0 ) {
        //     data.courseDuration = minuteDifference;
        // }

        if (this.formRequestData.videoUrl !== payload.videoUrl) {
            data.videoUrl = payload.videoUrl as string;
        }

        if (this.formRequestData.backgroundImageUrl !== payload.backgroundImageUrl) {
            data.backgroundImageUrl = payload.backgroundImageUrl as string;
        }

        if (this.formRequestData.videoThumbnailUrl !== payload.videoThumbnailUrl) {
            data.videoThumbnailUrl = payload.videoThumbnailUrl as string;
        }

        if (this.formRequestData.courseImageUrl !== payload.courseImageUrl) {
            data.courseImageUrl = payload.courseImageUrl as string;
        }

        // Check Weekdays length
        if (this.formRequestData.weekDays.length !== payload.weekDays.length) {
            data.weekDays = payload.weekDays;
        } else {
            // If they are the same length, we can compare the values
            let isSame = true;
            for (let index = 0; index < this.formRequestData.weekDays.length; index++) {
                if (this.formRequestData.weekDays[index] !== payload.weekDays[index]) {
                    isSame = false;
                }
            }
            if (!isSame) {
                data.weekDays = payload.weekDays;
            }
        }

        var currentTimestamp = "_" + new Date().valueOf(); // to make unique file name

        if (
            typeof payload.videoThumbnailUrl !== 'string' && 
            payload.videoThumbnailUrl &&
            payload.videoThumbnailUrl.originalFile
        ) {
            const backgroundResponse = await uploadAttachment(
                payload.videoThumbnailUrl.originalFile,
                payload.courseName + currentTimestamp + "_video_thumbnail",
                `thumbnail`
            );
            data.videoThumbnailUrl = backgroundResponse;
        }

        if (
            typeof payload.backgroundImageUrl !== 'string' && 
            payload.backgroundImageUrl &&
            payload.backgroundImageUrl.originalFile
        ) {
            const backgroundResponse = await uploadAttachment(
                payload.backgroundImageUrl.originalFile,
                payload.courseName + currentTimestamp,
                `background`
            );
            data.backgroundImageUrl = backgroundResponse;
        }

        if (
            typeof payload.courseImageUrl !== 'string' && 
            payload.courseImageUrl &&
            payload.courseImageUrl.originalFile
        ) {
            const courseResponse = await uploadAttachment(
                payload.courseImageUrl.originalFile,
                payload.courseName + currentTimestamp,
                `thumbnail`
            );
            data.courseImageUrl = courseResponse;
        }

        // Check Study Track and add them if the data is not null or undefined

        if (!this.formRequestData.studyTrack[`january`] || (this.formRequestData.studyTrack[`january`] !== payload.studyTrack[`january`])) {
            data.january = payload.studyTrack[`january`];
        }
        if (!this.formRequestData.studyTrack[`february`] || (this.formRequestData.studyTrack[`february`] !== payload.studyTrack[`february`])) {
            data.february = payload.studyTrack[`february`];
        }
        if (!this.formRequestData.studyTrack[`march`] || (this.formRequestData.studyTrack[`march`] !== payload.studyTrack[`march`])) {
            data.march = payload.studyTrack[`march`];
        }
        if (!this.formRequestData.studyTrack[`april`] || (this.formRequestData.studyTrack[`april`] !== payload.studyTrack[`april`])) {
            data.april = payload.studyTrack[`april`];
        }
        if (!this.formRequestData.studyTrack[`may`] || (this.formRequestData.studyTrack[`may`] !== payload.studyTrack[`may`])) {
            data.may = payload.studyTrack[`may`];
        }
        if (!this.formRequestData.studyTrack[`june`] || (this.formRequestData.studyTrack[`june`] !== payload.studyTrack[`june`])) {
            data.june = payload.studyTrack[`june`];
        }
        if (!this.formRequestData.studyTrack[`july`] || (this.formRequestData.studyTrack[`july`] !== payload.studyTrack[`july`])) {
            data.july = payload.studyTrack[`july`];
        }
        if (!this.formRequestData.studyTrack[`august`] || (this.formRequestData.studyTrack[`august`] !== payload.studyTrack[`august`])) {
            data.august = payload.studyTrack[`august`];
        }
        if (!this.formRequestData.studyTrack[`september`] || (this.formRequestData.studyTrack[`september`] !== payload.studyTrack[`september`])) {
            data.september = payload.studyTrack[`september`];
        }
        if (!this.formRequestData.studyTrack[`october`] || (this.formRequestData.studyTrack[`october`] !== payload.studyTrack[`october`])) {
            data.october = payload.studyTrack[`october`];
        }
        if (!this.formRequestData.studyTrack[`november`] || (this.formRequestData.studyTrack[`november`] !== payload.studyTrack[`november`])) {
            data.november = payload.studyTrack[`november`];
        }
        if (!this.formRequestData.studyTrack[`december`] || (this.formRequestData.studyTrack[`december`] !== payload.studyTrack[`december`])) {
            data.december = payload.studyTrack[`december`];
        }
        this.courseEditStore.updateCurrentCourse(data);
    }

    @Watch(`courseEditStore.response`)
    redirectToCourseManagement() {
        if (this.courseEditStore.response) {
            this.courseStateStore.updateCourseIsModified(true);
            this.$router.push({
                path: `/courses`
            });
        }
    }

    @Watch(`courseEditStore.isTimeout`)
    redirectToCourses() {
        if (this.courseEditStore.isTimeout) {
            this.courseStateStore.updateCourseIsModified(true);
            this.$router.push({
                path: `/courses`
            });
        }
    }
}
