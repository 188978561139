



























import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { DropdownItem } from '@/models/dropdown';
@Component({})
export default class MultiSelectDropdown extends Vue {
  selectedValues: string[] = [];
  selectedDropdownItems: DropdownItem[] = [];
  isDropdownShown = false;

  @Prop({ required: true })
  selectedTexts!: string[];

  @Prop({ default: false })
  isDisabled!: boolean;

  // This is for Edit purposes. Ideally for set the dropdownState
  @Prop()
  previousSelectedValues!: string[];

  @Prop({ required: true })
  data!: DropdownItem[];

  // Close Dropdown function
  closeDropdown(event: any) {
    if (!this.$el.contains(event.target)) {
      this.isDropdownShown = false;
    }
  }

  created() {
    document.addEventListener('click', this.closeDropdown);
  }

  beforeDestroy() {
    document.removeEventListener(`click`, this.closeDropdown);
  }

  get displayText() {
    return this.selectedTexts.join(', ');
  }

  @Watch(`previousSelectedValues`)
  updateCurrentValue() {
    if (this.previousSelectedValues.length > 0 && this.data.length > 0) {
      const output: DropdownItem[] = [];
      this.previousSelectedValues.forEach((value) => {
        const itemIndex = this.data.findIndex((item) => {
          return item.value === value;
        });
        if (itemIndex > -1) {
          this.selectedValues.push(this.data[itemIndex].value);
          output.push(this.data[itemIndex]);
        }
      });
      this.selectedDropdownItems = output;
      this.updateSelectedItems(output);
    }
  }

  updateSelectedState(selectedItem: DropdownItem) {
    if (this.selectedDropdownItems.includes(selectedItem)) {
      this.selectedDropdownItems = this.selectedDropdownItems.filter((data) => {
        return data.value !== selectedItem.value;
      });
    } else {
      this.selectedDropdownItems.push(selectedItem);
    }
    this.selectedValues = [];
    this.selectedDropdownItems.forEach((data) => {
      this.selectedValues.push(data.value);
    });
    this.updateSelectedItems(this.selectedDropdownItems);
  }

  @Emit(`select`)
  updateSelectedItems(selectedItems: DropdownItem[]) {
    this.isDropdownShown = false;
    return selectedItems;
  }
}
