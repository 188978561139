


















































































































































































































































































































































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import Upload from '@/components/common/Upload.vue';
import SplitInput from '@/components/common/SplitInput.vue';
import { MultiCourseItemType } from '@/models/courses';
import {
  AcceptedImageFileType,
  AcceptedVideoFileType,
  FirstSixMonths,
  LastSixMonths,
  NumberToWeekDaysMap,
  WeekDaysToNumberMap,
  WeekDaysObjectArray,
} from '@/utils/constants';
import Editor from '@tinymce/tinymce-vue';
import { padIdWithZeroes } from '@/utils/helper';
import MultiSelectDropdown from '@/components/common/MultiSelectDropdown.vue';
import moment from 'moment';
import { UploadAttachmentWithThumbnail } from '@/models/generic';
@Component({
  components: { Dropdown, Upload, SplitInput, Editor, MultiSelectDropdown },
})
export default class CourseFormTemplate extends Vue {
  pageCounter = 1;
  maximumCounter = 3;
  minimumDate = moment(new Date())
    .startOf('day')
    .toDate();
  // First Page
  selectedCourseName = '';
  selectedCourseLanguage = '- Select -';
  selectedCourseSubject = '- Select -';
  selectedIsFeaturedCourse = false;
  selectedCourseLevel = '- Select -';
  selectedCourseTutor = '- Select -';
  selectedCourseSummary = '';
  selectedCourseThumbnail: UploadAttachmentWithThumbnail | null = null;
  selectedBackgroundImage: UploadAttachmentWithThumbnail | null = null;
  selectedVideoThumbnail: UploadAttachmentWithThumbnail | null = null;
  // Second page
  selectedCourseTimetableStartTime: Date | null = null;
  selectedCourseStartDate: Date | null = null;
  selectedCourseEndDate: Date | null = null;
  selectedCourseTimetableEndDate: Date | null = null;
  selectedCourseTimetableEndTime: Date | null = null;
  selectedCoursePrice = '';
  selectedCourseDiscountedPrice = '';
  selectedCourseRepeatType = 'Never';
  selectedCourseRepeatNumber = 1;
  selectedCourseRepeatOn = '';
  selectedCourseRepeatOnDay = 'number';
  selectedWeekDays: number[] = [];
  WeekDaysObjectArray = WeekDaysObjectArray;
  // Third Page
  selectedStudyTrack: { [key: string]: string } = {};
  isFirstPageButtonDisabled = true;
  isSecondPageButtonDisabled = true;
  isCourseLevelDropdownDisabled = true;
  isCourseTutorDropdownDisabled = true;
  firstSixMonths = FirstSixMonths;
  lastSixMonths = LastSixMonths;
  courseTimetableDayData: DropdownItem[] = [];
  courseMonthDurationData: DropdownItem[] = [];
  courseWeekDurationData: DropdownItem[] = [];
  currentSource: MultiCourseItemType = {
    id: 0,
    formId: 0,
    subjectId: 0,
    isFeatured: false,
    languageId: 0,
    tuitionId: 1, //TODO: Add a Tuition Centre dropdown
    teacherId: 0,
    courseName: '',
    price: '',
    discountPrice: '',
    description: '',
    startDate: null,
    endDate: null,
    repeatType: 'never',
    repeatOnDay: 'number',
    repeatNumber: 1,
    weekDays: [],
    courseStartTime: null,
    courseEndTime: null, // This is for Class End Time
    videoUrl: '',
    backgroundImageUrl: '',
    videoThumbnailUrl: '',
    courseImageUrl: '',
    studyTrack: {},
  };
  // Edit variables
  previousSelectedCourseLanguage = '';
  previousSelectedCourseSubject = '';
  previousSelectedCourseLevel = '';
  previousSelectedCourseTutor = '';
  previousSelectedCourseImage = '';
  previousSelectedVideoUrl = '';
  previousSelectedBackgroundImage = '';
  previousSelectedCourseRepeatType = '';
  previousSelectedVideoThumbnailUrl = '';

  @Prop({ default: false })
  isEditMode!: boolean;

  @Prop({ required: true })
  courseLanguageData!: DropdownItem[];

  @Prop({ required: true })
  courseSubjectData!: DropdownItem[];

  @Prop({ required: true })
  courseLevelData!: DropdownItem[];

  @Prop({ required: true })
  courseTutorData!: DropdownItem[];

  @Prop({ required: true })
  courseRepeatType!: DropdownItem[];

  @Prop()
  currentCourseData!: MultiCourseItemType;

  @Prop()
  isCourseNameDuplicated!: boolean;

  @Prop()
  courseSubjectPrefixId!: number;

  @Prop()
  courseLevelPrefixId!: number;

  get currentId() {
    return padIdWithZeroes(parseInt(this.$route.params.id));
  }

  get counter() {
    return `${this.pageCounter} / ${this.maximumCounter}`;
  }

  get languages() {
    return this.courseLanguageData;
  }

  get subjects() {
    return this.courseSubjectData;
  }

  get levels() {
    return this.courseLevelData;
  }

  get tutors() {
    return this.courseTutorData;
  }

  get imageFileTypes() {
    return AcceptedImageFileType;
  }

  get videoFileTypes() {
    return AcceptedVideoFileType;
  }

  get isNameError() {
    return this.isCourseNameDuplicated;
  }

  get minimumCourseTimetableEndDate() {
    return this.selectedCourseTimetableStartTime
      ? moment(this.selectedCourseTimetableStartTime)
          .add(1, `h`)
          .toDate()
      : null;
  }

  private get TinyMceAPIKey() {
    return process.env.VUE_APP_TINY_MCE_API_KEY;
  }

  get courseTextConfig() {
    return {
      height: 700,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help',
    };
  }

  get endDate() {
    return this.selectedCourseEndDate
      ? moment(this.selectedCourseEndDate).format(`DD MMMM YYYY`)
      : null;
  }

  @Watch(`isNameError`)
  forceNavigationToNameField() {
    if (this.isNameError) {
      this.pageCounter = 1;
      this.isFirstPageButtonDisabled = true;
      this.isSecondPageButtonDisabled = true;
    }
  }

  @Watch(`courseSubjectPrefixId`)
  autoFillSubjectSelection() {
    if (this.courseSubjectPrefixId > -1) {
      this.previousSelectedCourseSubject = this.courseSubjectPrefixId.toString();
    }
  }

  @Watch(`courseLevelPrefixId`)
  autoFillLevelSelection() {
    if (this.courseLevelPrefixId > -1) {
      this.previousSelectedCourseLevel = this.courseLevelPrefixId.toString();
    }
  }

  capitalizeWords(data: string) {
    return data.toUpperCase();
  }

  ucWords(data: string) {
    data = data.toLowerCase().replace(/\b[a-z]/g, function(letter) {
      return letter.toUpperCase();
    });
    return data;
  }

  mounted() {
    // Only Edit has this, if so map things back
    /*
            Important Dev Note:
            you will need to spread on nested levels since it will only make shallow copies
            you can use lodash.cloneDeep for this.
        */
    if (this.currentCourseData) {
      this.currentSource = {
        ...this.currentCourseData,
        studyTrack: {
          ...this.currentCourseData.studyTrack,
        },
      };
      this.selectedCourseName = this.currentSource.courseName;
      this.previousSelectedCourseLanguage = this.currentSource.languageId.toString();
      this.previousSelectedCourseSubject = this.currentSource.subjectId.toString();
      this.previousSelectedCourseLevel = this.currentSource.formId.toString();
      this.previousSelectedCourseTutor = this.currentSource.teacherId.toString();
      // this.previousSelectedCourseTimetableDays = this.currentSource.weekDays;
      this.currentSource.weekDays.forEach((item) => {
        switch (item) {
          case `Monday`: {
            this.selectedWeekDays.push(WeekDaysToNumberMap.Monday);
            break;
          }
          case `Tuesday`: {
            this.selectedWeekDays.push(WeekDaysToNumberMap.Tuesday);
            break;
          }
          case `Wednesday`: {
            this.selectedWeekDays.push(WeekDaysToNumberMap.Wednesday);
            break;
          }
          case `Thursday`: {
            this.selectedWeekDays.push(WeekDaysToNumberMap.Thursday);
            break;
          }
          case `Friday`: {
            this.selectedWeekDays.push(WeekDaysToNumberMap.Friday);
            break;
          }
          case `Saturday`: {
            this.selectedWeekDays.push(WeekDaysToNumberMap.Saturday);
            break;
          }
          case `Sunday`: {
            this.selectedWeekDays.push(WeekDaysToNumberMap.Sunday);
            break;
          }
          default: {
            break;
          }
        }
      });
      this.currentSource.videoUrl =
        this.currentSource.videoUrl === null ? '' : this.currentSource.videoUrl;
      (this.selectedCourseTimetableEndTime = this.currentSource.courseEndTime),
        (this.selectedCourseEndDate = this.currentSource.endDate);
      (this.selectedCourseTimetableStartTime = this.currentSource.courseStartTime),
        (this.selectedCourseStartDate = this.currentSource.startDate);
      this.selectedCourseTimetableEndDate = this.currentSource.startDate;
      this.selectedCourseRepeatNumber = this.currentSource.repeatNumber;
      this.selectedCoursePrice = this.currentSource.price;
      this.selectedCourseDiscountedPrice = this.currentSource.discountPrice;
      this.selectedCourseSummary = this.currentSource.description;
      this.previousSelectedCourseImage = this.currentSource.courseImageUrl as string;
      this.previousSelectedVideoUrl = this.currentSource.videoUrl as string;
      this.previousSelectedBackgroundImage = this.currentSource.backgroundImageUrl as string;
      this.previousSelectedVideoThumbnailUrl = this.currentSource.videoThumbnailUrl as string;

      this.selectedIsFeaturedCourse = this.currentSource.isFeatured;
      // Update Study Track.
      const months = FirstSixMonths.concat(LastSixMonths);
      months.forEach((month) => {
        if (this.currentSource.studyTrack[month]) {
          this.selectedStudyTrack[month] = this.currentSource.studyTrack[month];
        }
      });
      this.currentSource.repeatType =
        this.currentSource.repeatType == null ? '' : this.currentSource.repeatType.toLowerCase();
      this.selectedCourseRepeatType =
        this.currentSource.repeatType === ''
          ? this.selectedCourseRepeatType
          : this.ucWords(this.currentSource.repeatType.toString());
      this.previousSelectedCourseRepeatType = this.selectedCourseRepeatType.toLowerCase();
      this.selectedCourseRepeatOnDay =
        this.currentSource.repeatOnDay === null
          ? 'number'
          : this.currentSource.repeatOnDay.toString();
      this.validateCurrentForm();
    }
  }

  validateCourseEndDate() {
    var startDate = this.selectedCourseStartDate === null? '': this.selectedCourseStartDate.getTime();
    var endDate = this.selectedCourseTimetableEndDate === null? '': this.selectedCourseTimetableEndDate.getTime();
    if(endDate === '') return true;
    return startDate == endDate;
  }

  updateCourseName(data: string) {
    this.selectedCourseName = data.trim();
    this.currentSource.courseName = data.trim();
    this.validateCurrentForm();
    this.resetNameError();
  }

  updateCourseLanguageState(data: DropdownItem) {
    this.selectedCourseLanguage = data.text;
    this.currentSource.languageId = parseInt(data.value);
  }

  updateCourseLanguage(data: DropdownItem) {
    this.updateCourseLanguageState(data);
    this.validateCurrentForm();
  }

  updateIsFeaturedCourse(data: boolean) {
    this.selectedIsFeaturedCourse = data;
    this.currentSource.isFeatured = data;
    this.validateCurrentForm();
  }

  updateCourseSubjectState(data: DropdownItem) {
    this.selectedCourseSubject = data.text;
    this.currentSource.subjectId = parseInt(data.value);
    this.isCourseLevelDropdownDisabled = false;
  }

  updateCourseSubject(data: DropdownItem) {
    this.updateCourseSubjectState(data);
    this.isCourseLevelDropdownDisabled = true;
    this.validateCurrentForm();
    if (this.currentSource.subjectId > 0) {
      this.isCourseLevelDropdownDisabled = false;
      // Reset tutor dropdown
      this.isCourseTutorDropdownDisabled = true;
      this.selectedCourseLevel = '- Select -';
      this.selectedCourseTutor = '- Select -';
      this.currentSource.teacherId = 0;
      this.retrieveFormDropdown();
    }
  }

  updateCourseLevelState(data: DropdownItem) {
    this.selectedCourseLevel = data.text;
    this.currentSource.formId = parseInt(data.value);
    this.isCourseTutorDropdownDisabled = false;
  }

  updateCourseLevel(data: DropdownItem) {
    this.updateCourseLevelState(data);
    this.isCourseTutorDropdownDisabled = true;
    this.validateCurrentForm();
    if (this.currentSource.formId > 0) {
      this.isCourseTutorDropdownDisabled = false;
      this.selectedCourseTutor = '- Select -';
      this.currentSource.teacherId = 0;
      this.retrieveTutorDropdown();
    }
  }

  updateCourseTutorState(data: DropdownItem) {
    this.selectedCourseTutor = data.text;
    this.currentSource.teacherId = parseInt(data.value);
  }

  updateCourseTutor(data: DropdownItem) {
    this.updateCourseTutorState(data);
    this.validateCurrentForm();
  }

  updateCourseRepeatNumber(data: Number) {
    this.selectedCourseRepeatNumber = Number(data);
    this.currentSource.repeatNumber = Number(data);
    this.validateCurrentForm();
    this.resetNameError();
  }

  updateCourseRepeatType(data: DropdownItem) {
    this.selectedCourseRepeatType = data.text;
    this.currentSource.repeatType = data.value;
    var dateTime = this.selectedCourseStartDate == null? new Date(): new Date(this.selectedCourseStartDate);
    if(data.text.toLowerCase() == "never") this.updateCourseEndDate(dateTime)
    this.validateCurrentForm();
  }

  updateRepeatOnDay(payload: String) {
    this.selectedCourseRepeatOnDay = payload.trim();
    this.currentSource.repeatOnDay = payload.trim();
    this.validateCurrentForm();
  }

  updateWeekRepeatDays(data: Number) {
    var payload = Number(data);
    if (this.selectedWeekDays.includes(payload)) {
      const index = this.selectedWeekDays.indexOf(payload);
      if (index > -1) {
        this.selectedWeekDays.splice(index, 1);
      }
    } else {
      this.selectedWeekDays.push(payload);
    }
    this.selectedWeekDays.sort();
    const sortedDays: string[] = [];
    this.selectedWeekDays.forEach((value) => {
      switch (value) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6: {
          sortedDays.push(NumberToWeekDaysMap[value]);
          break;
        }
        default: {
          break;
        }
      }
    });
    this.currentSource.weekDays = sortedDays;
    this.validateCurrentForm();
  }

  showWeekDays(payload: Date, type: String) {
    var count = ['', 'first', 'second', 'third', 'fourth', 'fifth'];
    var str = '';
    if (payload == null) return str;
    var mJsDate = moment(payload);
    if (type == 'number') {
      str = mJsDate.format('DD');
    } else {
      str = count[Math.ceil(mJsDate.date() / 7)] + ' ' + mJsDate.format('dddd');
    }

    return str;
  }

  updateCourseTimetableStartTime(payload: Date) {
    this.selectedCourseTimetableStartTime = payload;
    this.currentSource.courseStartTime = payload;
    this.validateCurrentForm();
  }

  updateCourseTimetableEndTime(payload: Date) {
    this.selectedCourseTimetableEndTime = payload;
    this.currentSource.courseEndTime = payload;
    this.validateCurrentForm();
  }

  updateCourseTimetableEndDate(payload: Date) {
    this.validateCurrentForm();
  }

  updateCourseStartDate(payload: Date) {
    this.selectedCourseStartDate = payload;
    this.currentSource.startDate = payload;
    if(this.selectedCourseEndDate == null) this.updateCourseEndDate(payload);
    else if(this.selectedCourseEndDate < this.currentSource.startDate) this.updateCourseEndDate(payload);
    this.validateCurrentForm();
  }

  updateCourseEndDate(payload: Date) {
    this.selectedCourseEndDate = payload;
    this.currentSource.endDate = payload;
    this.validateCurrentForm();
  }

  updateCoursePrice(data: string) {
    this.selectedCoursePrice = data;
    this.currentSource.price = data;
    this.validateCurrentForm();
  }

  updateCourseDiscountedPrice(data: string) {
    this.selectedCourseDiscountedPrice = data;
    this.currentSource.discountPrice = data;
    this.validateCurrentForm();
  }

  updateCourseSummary(data: string) {
    this.selectedCourseSummary = data;
    this.currentSource.description = data;
    this.validateCurrentForm();
  }

  updateStudyTrack(key: string, data: string) {
    this.selectedStudyTrack[key] = data;
    this.currentSource.studyTrack[key] = data;
    this.validateCurrentForm();
  }

  // Attachments
  updateCourseThumbnail(payload: UploadAttachmentWithThumbnail | null) {
    this.selectedCourseThumbnail = payload;
    this.currentSource.courseImageUrl = payload!.originalFile ? payload : null;
    this.validateCurrentForm();
  }

  updateBackgroundImage(payload: UploadAttachmentWithThumbnail | null) {
    this.selectedBackgroundImage = payload;
    this.currentSource.backgroundImageUrl = payload!.originalFile ? payload : null;
    this.validateCurrentForm();
  }

  updateVideoThumbnail(payload: UploadAttachmentWithThumbnail | null) {
    this.selectedVideoThumbnail = payload;
    this.currentSource.videoThumbnailUrl = payload!.originalFile ? payload : null;
    this.validateCurrentForm();
  }

  updateCourseVideo(payload: string) {
    this.currentSource.videoUrl = payload ? payload : '';
    this.validateCurrentForm();
  }

  updateCourseVideoURL(data: string) {
    this.previousSelectedVideoUrl = data;
    this.currentSource.videoUrl = data;
    this.validateCurrentForm();
  }

  // Validation Method
  validateCurrentForm() {
    this.isFirstPageButtonDisabled = true;
    this.isSecondPageButtonDisabled = true;
    // Validate Form Part 1
    if (
      this.currentSource.courseName.length > 0 &&
      this.currentSource.subjectId > 0 &&
      this.currentSource.formId > 0 &&
      this.currentSource.languageId > 0 &&
      this.currentSource.teacherId > 0 &&
      ((typeof this.currentSource.courseImageUrl === 'string' &&
        this.currentSource.courseImageUrl.length > 0) ||
        this.currentSource.courseImageUrl) &&
      ((typeof this.currentSource.backgroundImageUrl === 'string' &&
        this.currentSource.backgroundImageUrl.length > 0) ||
        this.currentSource.backgroundImageUrl) &&
      (this.currentSource.videoUrl == '' ||
        (this.currentSource.videoUrl !== '' &&
          this.currentSource.videoUrl.substr(this.currentSource.videoUrl.length - 3) == 'mp4'))
    ) {
      this.isFirstPageButtonDisabled = false;
    }

    // Validate Form Part 2
    if (
      this.currentSource.courseStartTime &&
      this.currentSource.courseEndTime &&
      this.currentSource.startDate &&
      this.currentSource.price.length > 0 &&
      Number(this.currentSource.price) >= 0 &&
      this.validateCourseEndDate() &&
      (
        (
          ['daily', 'monthly', 'yearly'].includes(this.currentSource.repeatType) &&
          Number(this.currentSource.repeatNumber) > 0
        ) ||
        (
          this.currentSource.repeatType == 'weekly' &&
          Number(this.currentSource.repeatNumber) > 0 &&
          this.currentSource.weekDays.length > 0
        ) ||
        this.currentSource.repeatType == 'never'
      )
    ) {
      this.isSecondPageButtonDisabled = false;
    }

    // Condition if there is a discount price
    // if (this.selectedCourseDiscountedPrice !== '') {
    //   this.isSecondPageButtonDisabled = true;
    // }

    if (
      this.selectedCourseDiscountedPrice !== '' &&
      this.currentSource.discountPrice.length > 0 &&
      Number(this.currentSource.discountPrice) < Number(this.currentSource.price)
    ) {
      this.isSecondPageButtonDisabled = false;
    }
  }

  @Emit(`retrieveForms`)
  retrieveFormDropdown() {
    return this.currentSource.subjectId;
  }
  @Emit(`retrieveTutors`)
  retrieveTutorDropdown() {
    return this.currentSource.formId;
  }
  @Emit(`submit`)
  submitForm() {
    return this.currentSource;
  }
  @Emit(`resetNameError`)
  resetNameError() {
    return false;
  }
}
