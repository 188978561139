import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { LanguageItemResponse, LanguageListResponse } from '@/models/languages';

@Module({
    namespaced: true,
    dynamic: true,
    name: `languages.list`,
    store,
})
class LanguageListStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: LanguageItemResponse[] | null = null; // TODO: Replace this with a better interface when we have an API

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: LanguageItemResponse[]) {
        this.response = payload;
    }

    @Action 
    retrieveLanguages() {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.get<LanguageListResponse>(`/masterdata/languages`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data.objects);
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching languages. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(LanguageListStore);